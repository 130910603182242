import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EmailService } from '../../services/email.service';
import { DadosPerfilService } from '../../shared/services/dados-perfil.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {

  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert!: NgbAlert;

  private _success = new Subject<string>();

  staticAlertClosed = false;
  successMessage = '';

  oForm!: FormGroup;

  focus!: boolean;
  focus1!: boolean;

  visible = true;
  dismissible = true;

  eDadosGabriel = this.oDadosPerfilService.getDadosPerfil()[0];
  eDadosJupiter = this.oDadosPerfilService.getDadosClientes()[0];
  eDadosSoftvision = this.oDadosPerfilService.getDadosClientes()[1];

  constructor(
    oFormBuilder: FormBuilder,
    private oEmailService: EmailService,
    private oDadosPerfilService: DadosPerfilService,
  ) {
    this.oForm = oFormBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this._success.subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(5000)).subscribe(() => {
      if (this.selfClosingAlert) {
        this.selfClosingAlert.close();
      }
    });
  }

  onSendEmail() {
    if (this.validate()) {
      this.oEmailService.sendEmail(this.oForm.value).subscribe((res: any) => {
        if (res.ok) {
          this.changeSuccessMessage(true);
        } else {
          this.changeSuccessMessage(false);
        }
      });
    } else {
      this.changeSuccessMessage(false);
    }
  }

  public validate(): boolean | undefined {
    return this.name?.valid && this.email?.valid && this.message?.valid;
  }

  public changeSuccessMessage(success: boolean) {
    this._success.next(success ? `Messagem enviada com sucesso!` : `É necessário preencher todos os campos!`);
  }

  get name() {
    return this.oForm.get('name');
  }
  get email() {
    return this.oForm.get('email');
  }
  get message() {
    return this.oForm.get('message');
  }
}
