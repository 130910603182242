import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DadosPerfilService {

  constructor() { }

  getDadosPerfil() {
    return [
      {
        FOTO: './assets/img/faces/gabriel_lopes.jpeg',
        INSTAGRAM: 'https://www.instagram.com/ogabriellnl/',
        LINKEDIN: 'https://www.linkedin.com/in/gabriel-lopes-53309816b/',
        NOME: 'Gabriel Lopes',
        TITULO: 'CEO, Analista Desenvolvedor e Gerente de Projetos',
        DESCRICAO: 'Atuando há 6 anos na área de Desenvolvimento de sistemas, começando como estagiário e aprendendo de tudo um pouco, no começo foi um grande desafio, mas com muito esforço e com a ajuda de ótimos profissionais fui trilhando meu caminho e ganhando meu espaço. Desde HTML básico à gerenciamento de Banco de Dados e mexendo com linguagens de programação Web. Atualmente trabalho em um projetos nacionasi e internacionais onde tenho crescido muito, tanto como pessoalmente quanto profissionalmente, e desde então venho buscando cada vez mais aprimorar meus conhecimentos e contribuir para a empresa e projeto, para que ambos cresçam juntos.',
        STATUS: 'Um grupo torna-se uma equipe quando cada membro está suficientemente seguro de si próprio e da sua contribuição para elogiar a habilidade dos outros. Ninguém pode assobiar uma sinfonia. É necessária uma orquestra para a tocar.'
      },
      {
        FOTO: './assets/img/faces/nathalia_cunha.jpeg',
        INSTAGRAM: 'https://www.instagram.com/cunhaa_nathalia/',
        LINKEDIN: 'https://www.linkedin.com/in/nathalia-cunha-santos-ba6a48223/',
        NOME: 'Nathalia Cunha',
        TITULO: 'Gerente de Comunicações',
        DESCRICAO: ' A força da equipe é cada membro individual. A força de cada membro é a equipe. Se pudermos rir juntos, podemos trabalhar juntos, o silêncio não é dourado, é mortal.',
        STATUS: 'A força da equipe é cada membro individual. A força de cada membro é a equipe. Se pudermos rir juntos, podemos trabalhar juntos, o silêncio não é dourado, é mortal.'
      }
    ];
  }

  getDadosClientes() {
    return [
      {
        FOTO: './assets/img/examples/jupiter-systems-solutions.JPG',
        SITE: 'https://jupiter.co.ao/',
        NOME: 'Jupiter Systems Solutions',
      },
      {
        FOTO: './assets/img/examples/softivision-solucao-tecnologia.JPG',
        SITE: 'https://www.softvision.com.br/',
        NOME: 'Softvisio Solução em Tecnologia',
      },
    ];
  }
}
