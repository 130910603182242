import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

    @Input() nome!: string
    @Input() titulo!: string;
    @Input() descricao!: string;

    constructor() { }

    ngOnInit() {}

}
