import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Email {
  name: string;
  email: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private readonly url: string = 'https://formspree.io/f/myyrzwko';

  constructor(private oHttp: HttpClient) { }

  sendEmail(data: Email): Observable<any> {
    let params: any = {};

    params['email'] = data.email;
    params['name'] = data.name;
    params['message'] = data.message;

    return this.oHttp.post(`${this.url}`, params).pipe(map((res: any) => {
      return res;
    }));
  }
}
